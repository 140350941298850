import Vue from 'vue'
import VueRouter from 'vue-router'
import { TokenService } from "./../apiServices/storageService"
import { supabase } from "./../supabase"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'Registration',
      component: () => import('@/views/pages/Authentication/Registration.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: () => import('@/views/pages/Authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/task-list',
      name: 'TaskList',
      component: () => import('@/views/pages/TaskList/TaskList.vue'),
      meta: {
        pageTitle: 'Task List',
        breadcrumb: [
          {
            text: 'Task',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-tasks-list',
      name: 'AdminTasksList',
      component: () => import('@/views/pages/Admin/TaskList/TaskList.vue'),
      meta: {
        pageTitle: 'Task List',
        breadcrumb: [
          {
            text: 'Task',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/create-task',
    //   name: 'createtask',
    //   component: () => import('@/views/pages/CreateTask/CreateTask.vue'),
    //   meta: {
    //     pageTitle: 'Create Task',
    //     breadcrumb: [
    //       {
    //         text: 'Create Task',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // console.log(to.name);
  let user = supabase.auth.user();
  // console.log("user", user, router);
  if (!user && to.name != 'login') {
    router.replace('/login');
    // to.push({ name: "login" });
    // next({ path: '/login' })
  }
  if (to.name != 'login' && to.name != '') {


    user.user_type = 'all';
    TokenService.saveToken(user);
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  } else {
    console.log("login")
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
    // next();
  }

})

export default router
