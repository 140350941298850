export default {
    namespaced: true,
    state: {
        user_details: {},
    },
    getters: {
        getUserDetails: state => state.user_details
    },
    actions: {},
    mutations: {
        SET_USER_DETAILS(state, val) {
            state.user_details = val
        }
    },
}