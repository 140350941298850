// LIVE
export const BASEURL = process.env.BASEURL;
export const FILESURL = process.env.FILESURL;
export const VITE_SUPABASE_URL = process.env.VITE_SUPABASE_URL;
export const VITE_SUPABASE_ANON_KEY = process.env.VITE_SUPABASE_ANON_KEY;
// DEV
// export const BASEURL = "http://localhost:8002/api";
// export const FILESURL = "http://localhost:8002";

export const API_VERSION = "v1.0";
