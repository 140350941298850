import store from "@/store";

export default {
  namespaced: true,

  state: {
    // Countries: [],
  },

  mutations: {
    // ON_SET_COUNTRIES: (state, payload) => {
    //   state.Countries = payload;
    // },
  },

  actions: {
    // onGetUniversityList: async (
    //   { dispatch, commit, state },
    //   { ...payload }
    // ) => {
    //   await commit("ON_SET_UNIVERSITY_LIST", []);
    //   HomeServices.getSearchList()
    //     .then(async (response) => {
    //       const { data } = response.data.data;
    //       if (response["data"] && response.data.status) {
    //         await commit("ON_SET_UNIVERSITY_LIST", data);
    //       } else {
    //         store.dispatch("AppSetting/showAdminErrorMessage", {
    //           message: data.message,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("Catch Error on onGetUniversityList", error);
    //     })
    //     .finally(async () => {});
    // },
  },

  getters: {
    // getCountries: (state) => state.Countries,
  },
};
